import * as Comlink from "comlink"

const BITS = 4096;

const CFG_STR = `[req]
prompt = no
distinguished_name = req_distinguished_name

[ req_distinguished_name ]
`;

export default class GenCert {
    constructor(err){
        this.worker = new Worker(new URL('worker.js', import.meta.url), {type: 'module'});
        this.Exec = Comlink.wrap(this.worker);
        this.ready = (async ()=>{
            this.exec = await new this.Exec();
            var fetches = [
                this.exec.get_command((new URL("openssl.wasm", import.meta.url)).href, "openssl"),
                this.exec.get_file((new URL("ca.key", import.meta.url)).href, "ca.key"),
                this.exec.get_file((new URL("ca.cer", import.meta.url)).href, "ca.cer"),
            ];
            await Promise.all(fetches);
        })();
        this.ready.catch(err);
        this.cancel = false;
    }

    async free(){
        if(this.cancel){
            this.cancel("canceled");
        }
        this.worker.terminate();
    }

    async gen_pk(){
        await this.ready;

        await this.exec`openssl rsa -in client.key -outform DER -passin pass:pass -pubout -out client.pub`;
        let binpk = this.exec.read_bin("client.pub");
        let hash = await this.exec`openssl sha256 client.pub`;
        return hash.substr(hash.indexOf(" ")+1);
    }

    async gen_key(){
        await this.ready;

        await this.exec.gen_rand("rand.dat")
        await (new Promise((resolve, reject)=>(async()=>{
            this.cancel = reject;
            await this.exec`openssl genrsa -rand rand.dat -passout pass:pass -out client.key ${BITS}`;
            this.cancel = false;
            resolve();
        })()));

        return await this.gen_pk();
    }

    async ul_p12(data){
        await this.ready;
        await this.exec.write_bin("orig.p12", data)
    }

    async decode_p12(pw){
        await this.ready;
        let output = await this.exec`openssl pkcs12 -in orig.p12 -passin pass:${pw} -nokeys`;
        if(output.indexOf("Mac verify error") >= 0){ // valid file; pass incorrect
            return false;
        }else if(output.indexOf("Bag Attributes") < 0){ // decoded incorrectly
            throw "invalid p12 file";
        }
        let cfg = {};
        await this.exec`openssl pkcs12 -passin pass:${pw} -in orig.p12 -clcerts -nokeys -out client.cer`;
        await this.exec.gen_rand("rand.dat");
        await this.exec`openssl pkcs12 -rand rand.dat -passin pass:${pw} -passout pass:pass -in orig.p12 -nocerts -out client.key`;
        cfg.pk = await this.gen_pk();

        let serial = await this.exec`openssl x509 -in client.cer -serial -noout`
        cfg.serial = parseInt(serial.substr(7), 16);

        let subj = await this.exec`openssl x509 -in client.cer -subject -noout`;
        subj = subj.slice(8, -1);
        for(let gr of subj.split(", ")){
            let split = gr.indexOf(" = ");
            let name = gr.substr(0, split);
            let valu = gr.substr(split+3);
            if(name == "O"){
                cfg.dispname = valu;
            }else if(name == "CN"){
                cfg.username = valu;
            }else if(name == "emailAddress"){
                cfg.email = valu;
            }
        }
        return cfg;
    }

    async gen_p12(cfg){
        await this.ready;

        let cfgStr = CFG_STR;

        if(cfg.dispname) cfgStr += `organizationName = ${cfg.dispname}\n`
        if(cfg.username) cfgStr += `commonName = ${cfg.username}\n`
        if(cfg.email)    cfgStr += `emailAddress = ${cfg.email}\n`

        this.exec.write_bin("req.cfg", cfgStr);

        await this.exec`openssl req -new -config req.cfg -key client.key -out client.req -passin pass:pass`;
        await this.exec`openssl x509 -req -in client.req -CA ca.cer -CAkey ca.key -set_serial ${cfg.serial} -extensions client -days 36500 -outform PEM -out client.cer`
        await this.exec`openssl pkcs12 -export -passout pass:${cfg.password} -inkey client.key -passin pass:pass -in client.cer -out client.p12`;

        return await this.exec.read_bin("client.p12");
    }
}
