import GenCert from "./gencert.js"

//thank so
const downloadURL = (data, fileName) => {
  const a = document.createElement('a')
  a.href = data
  a.download = fileName
  document.body.appendChild(a)
  a.style.display = 'none'
  a.click()
  a.remove()
}

const downloadBlob = (data, fileName, mimeType) => {
  const blob = new Blob([data], {
    type: mimeType
  })
  const url = window.URL.createObjectURL(blob)
  downloadURL(url, fileName)
  setTimeout(() => window.URL.revokeObjectURL(url), 1000)
}

const readFileAsBuffer = file => {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onload = event => {
      resolve(event.target.result);
    };
    fileReader.onabout = () => {
      reject();
    };
    fileReader.readAsArrayBuffer(file);
  });
};


export default class App {
    constructor(){
        if(!window.WebAssembly){
            console.error("no wasm");
            document.querySelector("#err-msg").textContent = "WebAssembly unsupported. Try enabling `javascript JIT` if disabled";
            return;
        }
        this.gen = new GenCert(this.init_fail.bind(this));

        this.setup_key_gen();
        this.setup_download();
        this.setup_upload();
        this.setup_upload_div();
    }

    init_fail(){
        document.querySelector("#err-msg").textContent = "loading failed";
        console.error("init fail");
    }

    start_key_gen(){
        if(this.key_gener)
            return this.key_gener;

        this.gen_inp.value = "";

        this.key_gener = (async ()=>{
            this.gen_but.disabled = true;
            try{
                this.gen_inp.value = await this.gen.gen_key();
            }catch(e){
                console.error(e);
                if(e === "canceled")
                    this.gen_msg.textContent = "canceled";
                else
                    this.gen_msg.textContent = "keygen failed";
            }
            this.gen_but.disabled = false;
            this.key_gener = false;
        })();
        return this.key_gener;
    }

    setup_key_gen(){
        this.gen_inp = document.querySelector("#gen-sk-pk");
        this.gen_msg = document.querySelector("#gen-sk-msg");
        this.gen_but = document.querySelector("#gen-sk");
        this.gen_but.addEventListener("click", e => {
            this.gen_msg.textContent = "";
            this.start_key_gen();
        });
        this.start_key_gen();
    }

    start_download(){
        if(this.downloader)
            return this.downloader

        const cfg = {
            username: this.username_inp.value,
            dispname: this.dispname_inp.value,
            email:    this.email_inp.value,
            password: this.password_inp.value,
            serial:   this.serial_inp.value,
        }

        if(!(cfg.username || cfg.dispname || cfg.email)){
            this.dl_msg.textContent = "at least one of email, dispname, or username must be set";
            return false;
        }

        this.dl_msg.textContent = "";

        this.downloader = (async ()=>{
            this.dl_but.disabled = true;
            try{
                if(this.key_gener)
                    await this.key_gener;
                const bin = await this.gen.gen_p12(cfg);
                downloadBlob(bin, `${cfg.username}-${cfg.serial}.p12`, "application/x-pkcs12");
            }catch(e){
                console.error(e);
                this.dl_msg.textContent = "download failed";
            }
            this.dl_but.disabled = false;
            this.downloader = false;
        })();
        return this.downloader;
    }

    setup_download(){
        this.username_inp = document.getElementsByName("username")[0];
        this.dispname_inp = document.getElementsByName("dispname")[0];
        this.email_inp    = document.getElementsByName("email")[0];
        this.password_inp = document.getElementsByName("password")[0];
        this.serial_inp   = document.getElementsByName("serial")[0];

        this.dl_msg = document.querySelector("#dl-msg");
        this.dl_but = document.querySelector("#download");
        this.dl_but.addEventListener("click", e => {
            this.start_download();
        });
    }

    async run_upload(file){
        let ungened = false;
        if(this.key_gener){
            ungened = true;
            await this.gen.free();
            this.gen = new GenCert(this.init_fail.bind(this));
        }
        const buf = await readFileAsBuffer(file);
        const bin = new Uint8Array(buf);

        this.gen.ul_p12(bin);
        let password = "", cfg;
        while(1){
            try{
                cfg = await this.gen.decode_p12(password);
            }catch(e){
                console.error(e);
                this.ul_div_msg.textContent = "invalid pkcs12 file passed";
                if(ungened)this.start_key_gen();
                return;
            }
            if(cfg !== false)
                break
            password = prompt("Enter Decryption Key for cert");
            if(password === null){
                this.ul_div_msg.textContent = "incorrect password";
                if(ungened)this.start_key_gen();
                return;
            }
        }

        this.password_inp.value = password;

        this.serial_inp.value = cfg.serial;
        this.gen_inp.value = cfg.pk;
        this.gen_msg.textContent = "";
        
        if(cfg.username) this.username_inp.value = cfg.username;
        if(cfg.dispname) this.dispname_inp.value = cfg.dispname;
        if(cfg.email)    this.email_inp.value = cfg.email;
        this.ul_div_msg.textContent = "";
    }

    start_upload(files){
        if(this.uploader){
            this.ul_div_msg.textContent = "upload already in progress";
            return;
        }
        if(files.length != 1){
            this.ul_div_msg.textContent = "need precisely one file";
            return;
        }

        this.ul_div_msg.textContent = "";

        this.uploader = (async ()=>{
            this.ul_but.disabled = true;
            let file = files[0];
            try{
                await this.run_upload(file);
            }catch(e){
                console.error(e);
                this.ul_div_msg.textContent = "upload failed";
            }
            this.uploader = false;
            this.ul_but.disabled = false;
        })();
    }

    setup_upload(){
        this.ul_but = document.getElementsByName("upload")[0];
        this.ul_but.addEventListener("click", e =>{
            this.ul_but.value = null;
        });
        this.ul_but.addEventListener("change", e =>{
            this.start_upload(this.ul_but.files);
        });
    }

    setup_upload_div(){
        this.ul_div = document.querySelector("#upload-cert");
        this.ul_div_msg = document.querySelector("#upload-cert-msg");
        document.addEventListener("dragenter", e => {
            e.preventDefault();
            this.ul_div.classList.add("active");
        });
        document.addEventListener("dragover", e => {
            e.preventDefault();
            this.ul_div.classList.add("active");
        });
        document.addEventListener("dragleave", e => {
            e.preventDefault();
            this.ul_div.classList.remove("active");
        });
        document.addEventListener("drop", e => {
            e.preventDefault();
            this.ul_div.classList.remove("active");
            this.start_upload(e.dataTransfer.files);
        });
    }
}

window.addEventListener("load", ()=>{
    new App()
});
